<template>
  <div>
    <fwsy-solution v-show="title == 'fwsysolution'"></fwsy-solution>
    <clothing-solution v-show="title == 'clothingsolution'"></clothing-solution>
    <jewelry-manage v-show="title == 'jewelrymanage'"></jewelry-manage>
    <file-manage v-show="title == 'filemanage'"></file-manage>
    <smoke-solution v-show="title == 'smokesolution'"></smoke-solution>
    <data-solution v-show="title == 'datasolution'"></data-solution>
    <medicine-solution v-show="title == 'medicinesolution'"></medicine-solution>
    <container-solution
      v-show="title == 'containersolution'"
    ></container-solution>
    <alcohol-solution v-show="title == 'alcoholsolution'"></alcohol-solution>
    <guns-solution v-show="title == 'gunssolution'"></guns-solution>
    <build-solution v-show="title == 'buildsolution'"></build-solution>
  </div>
</template>

<script>
import JewelryManage from "../components/schemedetails/JewelryManage";
import FileManage from "../components/schemedetails/FileManage";
import SmokeSolution from "../components/schemedetails/SmokeSolution";
import DataSolution from "../components/schemedetails/DataSolution";
import ContainerSolution from "../components/schemedetails/ContainerSolution";
import MedicineSolution from "../components/schemedetails/MedicineSolution";
import FwsySolution from "../components/schemedetails/FwsySolution";
import ClothingSolution from "../components/schemedetails/ClothingSolution";
import AlcoholSolution from "../components/schemedetails/AlcoholSolution";
import GunsSolution from "../components/schemedetails/GunsSolution";
import BuildSolution from "../components/schemedetails/BuildSolution";
export default {
  name: "solution",
  components: {
    FwsySolution,
    ClothingSolution,
    JewelryManage,
    FileManage,
    SmokeSolution,
    DataSolution,
    MedicineSolution,
    ContainerSolution,
    AlcoholSolution,
    GunsSolution,
    BuildSolution
  },
  data() {
    return {
      title: "",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
         this.title = route.meta.title;
         window.scrollTo(0, 0);
      },
      immediate: true,
    },
  },
};
</script>